jQuery(function ($) {
    $('.slider-sobre').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        fade: true,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: 'linear'
    });
    $('.slider-produtos').slick({
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: '<button type="button" class="slick-prev btn btn-link text-secondary fs-2" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next btn btn-link text-secondary fs-2" aria-label="next"><i class="bi bi-chevron-right"></i></button>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    });

    $('#form-contato').on('submit', function(e){
        e.preventDefault();
        var formData = $(this).serialize();
        var spinner = $("#spinner");
        var response = $("#response");
        spinner.removeClass('visually-hidden');
        $.post("/contato.php", formData, function(data){
            $('#form-contato')[0].reset();
            spinner.addClass('visually-hidden');
            response.html(data);
        });
    })
});